body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 200px;
  padding: 30px;
}

.modal-content2 {
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 50%;
  padding: 100px;
}

.modal-close {
  position: absolute;
  top: 15%;
  right: 20%;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 60px;
  color: #374957;
}

.modal-close2 {
  position: absolute;
  top: 23%;
  right: 25%;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 60px;
  color: #374957;
}

.mflex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.m1 {
  color: #696666;
  font-family: IBM Plex Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.m2 {
  color: #1b1c1e;
  font-family: IBM Plex Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.m3 {
  color: #1b1c1e;
  font-family: IBM Plex Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.24px;
  margin-bottom: 30px;
}

.modalBox {
  padding-top: 100px;
  text-align: center;
}

.modalinner {
  padding-bottom: 30px;
}

@media (max-width: 500px) {
  .modal-content {
    background-color: white;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 90%;
    padding: 30px;
  }

  .modal-close {
    position: absolute;
    top: 10%;
    right: 5%;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 60px;
    color: #374957;
  }
}
