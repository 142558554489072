@font-face {
  font-family: "Trueno";
  src: local("TruenoBd"), url(./fonts/trueno/TruenoBd.otf) format("truetype");
}

@font-face {
  font-family: "TruenoBlk";
  src: local("TruenoBlk"), url(./fonts/trueno/TruenoBlk.otf) format("truetype");
}

@font-face {
  font-family: "TruenoBlkOl";
  src: local("TruenoBlkOl"),
    url(./fonts/trueno/TruenoBlkOl.otf) format("truetype");
}

html {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.logo {
  width: 100px;
  display: block;
}

.admin-f {
  display: flex;
  justify-content: space-between;
}

.main {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.empid {
  font-family: "Trueno";
  font-size: 14px;
  font-weight: 700;
  color: #1b1c1e;
}

.emplinp {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 16px;
  border: 0.5px solid #1f1f1f;
  border-radius: 4px;
  width: 419px;
  height: 64px;
  color: #767475;
  margin-top: 5px;
  padding-left: 10px;
}

.working {
  font-family: "Trueno";
  font-size: 14px;
  font-weight: 700;
  color: #1b1c1e;
  margin-top: 60px;
  margin-bottom: 28px;
}

.eml-header {
  font-family: "Trueno";
  width: 60%;
  font-size: 48px;
  font-weight: 700;
  color: #1b1c1e;
  margin-top: 50px;
}

.eml-header1 {
  font-family: "Trueno";
  width: 60%;
  font-size: 48px;
  font-weight: 700;
  color: #1b1c1e;
  margin-top: 50px;
  margin-left: 75px;
}

.but-emp {
  width: 419px;
  height: 65px;
  background: #f4aa00;
  border-radius: 14px;
  display: block;
  color: white;
  border: none;
  margin-top: 32px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.radio {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

input[type="radio"]:checked {
  color: #f4aa00;
}

.sign-head {
  font-family: "Trueno";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  margin-top: 196px;
  margin-bottom: 48px;
  text-align: center;
}

.clock-text {
  font-family: "Trueno";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  color: white;
  margin-bottom: 10px;
}

.clock-text2 {
  font-family: "Trueno";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: white;
}

.clock {
  width: 359px;
  height: 162px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  background: #f4aa00;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 14px;
  font-family: "Trueno";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  color: white;
  border: none;
}

.section1 {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.sign-dash {
  background: #ffffff;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 14px;
  margin-top: 70px;
  padding: 30px;
  overflow-x: auto;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.act {
  font-family: "Trueno";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
}

.admin-button {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.admin-arrow {
  width: 50px;
}

.admin-profile {
  display: flex;
  justify-content: space-between;
}

.admin-section {
  display: flex;
  justify-content: space-between;
}

.employee-section {
  display: flex;
  justify-content: space-between;
  width: 30%;
}

.new-clock {
  width: 240px;
  height: 208px;
  background: #f4aa00;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 14px;
  font-family: "Trueno";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  color: white;
  border: none;
}

.new-clock1 {
  width: 240px;
  height: 208px;
  background: #767575;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 14px;
  font-family: "Trueno";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  color: white;
  border: none;
}

.admin-inner {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
}

.admin-header-input {
  background: #ffffff;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 4px;
  width: 422px;
  height: 60px;
  border: none;
  margin-left: 30px;
  padding-left: 10px;
}

.clock-div {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.clock-but {
  width: 136px;
  height: 56px;
  background: #f4aa00;
  border-radius: 14px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  border: none;
}

.clock-but1 {
  width: 136px;
  height: 56px;
  background: #767575;
  border-radius: 14px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  border: none;
}

.clock-but-emp {
  width: 220px;
  height: 56px;
  background: #f4aa00;
  border-radius: 14px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  border: none;
  display: flex;
  padding-top: 16px;
  padding-left: 16px;
}

.email-div {
  margin-bottom: 30px;
}

.login-inner {
  margin-top: 30%;
  margin-left: 50px;
}

.login-button {
  width: 419px;
  height: 65px;
  background: #f4aa00;
  border-radius: 14px;
  color: white;
  border: none;
  margin-top: 50px;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: orange;
  font-size: 10px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
  padding-top: 30px;
}

/*  Employee css */

@media (min-width: 769px) {
  .employee {
    display: flex;
  }

  .employee-box1 {
    height: 100vh;
    width: 50%;
    background: #f7f7fc;
  }

  .employee-box2 {
    width: 50%;
    margin-top: 222px;
    padding-left: 48px;
  }

  .employee-box21 {
    width: 50%;
    margin-top: 90px;
    padding-left: 48px;
  }

  .employee-head {
    font-family: "Trueno";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    width: 572px;
    margin-left: 76px !important;
    margin-top: 101px;
  }

  .logo {
    width: 100px;
    margin-left: 70px;
  }

  .label-input {
    font-family: "Trueno";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #1b1c1e;
    padding-bottom: 8px;
  }

  .input-emp {
    border: 0.5px solid #1f1f1f;
    border-radius: 4px;
    width: 305px;
    height: 48px;
    padding-left: 10px;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #767475;
  }

  .working {
    font-family: "Trueno";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    color: #1b1c1e;
    margin-bottom: 19px;
  }

  .radio {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
  }

  .employee-input {
    width: 90%;
    margin-left: 35px;
    margin-top: 58px;
  }

  .radio-group {
    width: 90%;
    margin-left: 35px;
    margin-top: 58px;
  }

  .button-att {
    width: 300px;
    display: block;
    margin-top: 59px;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: white;
    background: #f4aa00;
    border-radius: 14px;
    border: none;
    height: 48px;
    margin-bottom: 30px;
    margin-left: 30px;
  }
}

.nav-modal {
  position: absolute;
  background: #ffffff;
  border-radius: 14px;
  width: 297px;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  right: 20px;
  top: 110px;
}

.inner-nav {
  padding-left: 30px;
  padding-top: 10px;
}

.forgot {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 17px;
}

.nav-item {
  margin-bottom: 30px;
  display: flex;
  font-family: "Trueno";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #1f1f1f !important;
}

.icon {
  padding-right: 10px;
}

.admin-profile-mobile {
  display: none;
}

.modal {
  position: fixed;
  left: 0;
  top: 0px;
  right: 0px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  height: 100vh;
}

.modal-content {
  width: 60%;
  background-color: white;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  padding-bottom: 100px !important;
  overflow-y: auto;
  height: 500px;
}

.modal-input {
  border: 0.5px solid #1f1f1f;
  border-radius: 4px;
  width: 100%;
  height: 64px;
  padding-left: 5px;
}

.modal-label {
  font-family: "Trueno";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #1b1c1e;
  padding-bottom: 5px;
}

.inner-content {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  margin-bottom: 30px;
}

.input-m {
  border: 0.5px solid #1f1f1f;
  border-radius: 4px;
  width: 419px;
  height: 64px;
  width: 100%;
  padding-left: 10px;
  margin-bottom: 20px;
}

.label-m {
  padding-bottom: 10px;
}

.but-m {
  width: 419px;
  height: 65px;
  background: #f4aa00;
  border-radius: 14px;
  width: 100%;
  border: none;
  color: white;
  display: block;
  margin-bottom: 30px;
}

.close {
  margin-left: auto;
  padding: 30px;
  width: 30px;
}

.modal-button {
  width: 100%;
  height: 65px;
  background: #f4aa00;
  border-radius: 14px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  border: none;
  color: #ffffff;
}

.sheet {
  display: flex;
  justify-content: space-between;
}

.sheet-inner {
  display: flex;
  justify-content: space-between;
}

.inner-date {
  display: flex;
  width: 200px;
}
.react-datepicker-wrapper {
  width: 100px !important;
}

.date {
  padding-right: 10px;
}

@media (min-width: 769px) {
  .img1 {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media (max-width: 768px) {
  .modal-input {
    border: 0.5px solid #1f1f1f;
    border-radius: 4px;
    width: 100%;
    height: 64px;
    padding-left: 5px;
  }

  .modal-button {
    width: 100%;
    height: 65px;
    background: #f4aa00;
    border-radius: 14px;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    border: none;
    color: #ffffff;
  }

  .modal-content {
    width: 90%;
    background-color: white;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    padding-bottom: 100px !important;
    overflow-y: auto;
    height: 500px;
  }

  .inner-content {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    margin-bottom: 30px;
  }
}

.setting_box2 {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 14px;
  background-color: blue;
  margin-top: 100px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 100px;
}

.setting_text11 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: white;
  text-align: center;
  padding-top: 100px;
  margin-bottom: 48px;
}

.setting_box {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 14px;
  background-color: #000000;
  margin-top: 100px;
  height: 500px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 100px;
}

.setting_text1 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: white;
  text-align: center;
  padding-top: 100px;
  margin-bottom: 48px;
}

.setting_label {
  font-family: "Trueno";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: white;
  margin-bottom: 12px;
}

.setting_input {
  border: 0.5px solid #f4aa00;
  border-radius: 4px;
  height: 64px;
  width: 100%;
  padding-left: 10px;
}

.setting_form_box {
  margin-bottom: 30px;
}

.setting_but {
  width: 80%;
  height: 65px;
  background: #f4aa00;
  border-radius: 14px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: white;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: none;
}

.lbut {
  width: 200px;
  height: 50px;
  background: #f4aa00;
  border-radius: 14px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: white;
  display: block;
  border: none;
  margin-top: 30px;
}

.butt {
  width: 100px;
  height: 30px;
  background: #f4aa00;
  border-radius: 14px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: white;
  display: block;
  border: none;
}

@media (min-width: 1000px) {
  .setting_box {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 14px;
    background-color: #000000;
    margin-top: 100px;
    height: 500px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
  }

  .setting_but {
    width: 50%;
    height: 65px;
    background: #f4aa00;
    border-radius: 14px;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: none;
  }
}

.farm {
  margin-top: 100px;
}
.farm-item {
  background-color: aqua;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.item {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 10px;
}

.req {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  margin-bottom: 10px;
  color: #000000;
  margin-bottom: 30px;
}
