@media (max-width: 768px) {
  .employee {
    flex-direction: column;
  }
  .img1 {
    width: 274px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .admin-header-input {
    display: none;
  }

  .illustrate {
    width: 274px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .admin-button {
    justify-content: space-around;
  }

  .logo {
    width: 100px;
    margin-left: 20px;
  }

  .admin-profile-mobile {
    display: block;
  }

  .admin-profile {
    display: none;
  }

  .label-input {
    font-family: "Trueno";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    color: #1b1c1e;
    padding-bottom: 8px;
  }

  .input-emp {
    border: 0.5px solid #1f1f1f;
    border-radius: 4px;
    width: 305px;
    height: 48px;
    padding-left: 10px;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #767475;
  }

  .working {
    font-family: "Trueno";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    color: #1b1c1e;
    margin-bottom: 19px;
  }

  .employee-head {
    font-family: "Trueno";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    width: 200px;
    margin-top: 56px;
    margin-left: 35px;
  }

  .radio {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
  }

  .employee-input {
    width: 90%;
    margin-left: 35px;
    margin-top: 58px;
  }

  .radio-group {
    width: 90%;
    margin-left: 35px;
    margin-top: 58px;
  }

  .employee-box21 {
    width: 100%;
    margin-top: 90px;
    padding-left: 0px;
  }

  .emplinp {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-size: 16px;
    border: 0.5px solid #1f1f1f;
    border-radius: 4px;
    width: 90%;
    height: 64px;
    color: #767475;
    margin-top: 5px;
    padding-left: 10px;
  }

  .login-inner {
    margin-top: 30%;
    margin-left: 10px;
  }

  .eml-header1 {
    font-family: "Trueno";
    width: 70%;
    font-size: 28px;
    font-weight: 700;
    color: #1b1c1e;
    margin-top: 50px;
    margin-left: 10px;
  }

  .login-button {
    width: 80%;
    height: 65px;
    background: #f4aa00;
    border-radius: 14px;
    color: white;
    border: none;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 30px;
  }

  .button-att {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 59px;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: white;
    background: #f4aa00;
    border-radius: 14px;
    border: none;
    height: 48px;
    margin-bottom: 30px;
  }

  .admin-inner {
    display: block;

    padding-top: 32px;
  }

  .new-clock {
    width: 100px;
    height: 100px;
    background: #f4aa00;
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    border-radius: 14px;
    font-family: "Trueno";
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    color: white;
    border: none;
  }

  .new-clock1 {
    width: 100px;
    height: 100px;
    background: #767575;
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    border-radius: 14px;
    font-family: "Trueno";
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    color: white;
    border: none;
  }

  .clock-div {
    display: block;
    width: 300px;
  }

  .admin-section {
    display: block;
    width: 40%;
  }

  .clock-but {
    display: block;
  }

  .clock-but1 {
    display: block;
  }
}

.container {
  position: relative;
  padding-left: 20px;
  padding-right: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* employee dash */

@media (max-width: 768px) {
  .clock {
    width: 300px;
  }

  .section1 {
    width: 400px;
  }

  .sign-head {
    font-family: "Trueno";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 48px;
    text-align: center;
  }
}
