.card {
  background: #ffffff;
  box-shadow: 4px 4px 32px rgba(110, 103, 103, 0.08);
  border-radius: 16px;
  padding: 34px 24px 24px 24px;
  width: 350px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.ctext1 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
}

.ctext2 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  padding-top: 16px;
  color: #6b6666;
  padding-bottom: 32px;
}

.card-flex {
  display: flex;
  justify-content: space-between;
}

.detail {
  display: flex;
  justify-content: space-between;
}

.detail1 {
  background: #ffffff;
  border: 1px solid rgba(195, 179, 179, 0.96);
  padding-left: 72px;
  padding-top: 33px;
  box-shadow: 4px 4px 32px rgba(110, 103, 103, 0.08);
  border-radius: 16px;
  width: 25%;
  max-height: 500px;
}

.detail2 {
  width: 65%;
  background: #ffffff;
  border: 1px solid rgba(195, 179, 179, 0.96);
  /* shadow */
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  box-shadow: 4px 4px 32px rgba(110, 103, 103, 0.08);
  border-radius: 16px;
  box-sizing: border-box;
}

.dtext1 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #2d2828;
  padding-bottom: 16px;
}

.dtext2 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}

.dtext3 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #565656;
}

.dbox {
  margin-bottom: 16px;
}

.dtext4 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #2d2828;
}

.detail2-inner {
  display: flex;
  justify-content: space-between;
}

.select {
  border: 1px solid #c3b3b3;
  border-radius: 100px;
  height: 48px;
  display: block;
  padding: 10px;
}

.sflex {
  width: 220px;
  display: flex;
  justify-content: space-between;
}

.outer {
  border-top: 1px solid #c3b3b3;
  margin-top: 50px;
}

.date {
  background: rgba(14, 27, 145, 1);
  border-radius: 100px;
  width: 100px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 10px;
  position: relative;
  margin-top: -20px;
  z-index: 1;
}

.inner {
  border-bottom: 1px solid #c3b3b3;
  padding-bottom: 20px;
  padding-top: 20px;
}

.chat-flex {
  display: flex;
  margin-bottom: 14px;
}

.ctext3 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #2d2828;
  padding-right: 24px;
}

.ctext4 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #6b6666;
}

.butflex {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 20px;
}

.cinput {
  width: 90%;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 14px;
  height: 57px;
  padding-left: 16px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #6b6666;
}

.cardList {
  display: flex;
  flex-wrap: wrap;
}
